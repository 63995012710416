<template>
  <div class="admin-add-currency">
    <error :err.sync="error" />
    <form-generator
      :elements="elements"
      :data="currency"
      :handleUpdate="({ key }, v) => (currency[key] = v)"
    />
  </div>
</template>

<script>
import currencySchema from "@/validation-schemas/currency";
import FormGenerator from "@/components/form-generator.vue";
export default {
  components: { FormGenerator },
  name: "admin-add-currency",
  props: {
    currency: Object
  },
  data() {
    return {
      error: null,
      loading: false
    };
  },
  computed: {
    elements() {
      return [
        {
          key: "title",
          label: "name",
          type: "string"
        },
        {
          key: "code",
          label: "code",
          type: "string"
        },
        {
          key: "exchange_rate",
          label: "exchange-rate-to-gbp",
          type: "number"
        },
        {
          key: "is_primary",
          label: "is-primary-currency",
          type: "switch"
        },
        {
          text: "save",
          type: "button",
          className: "float-right mt-5 mt-sm-0",
          attrs: {
            loading: this.loading,
            block: this.isSmallScreen
          },
          events: {
            click: this.add
          }
        }
      ];
    }
  },
  methods: {
    async add() {
      this.error = null;
      const { currency } = this;
      const validation = currencySchema.validate(currency, this.joiOptions);
      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.loading = true;
        try {
          await this.$store.dispatch("currencies/update", currency);
          this.$emit("saved");
        } catch (err) {
          console.error(err);
        }
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
