import Joi from "joi";
import errorMessages from "./errorMessages.json";

export default Joi.object({
  title: Joi.string()
    .max(25)
    .required(),
  code: Joi.string()
    .max(3)
    .required()
})
  .unknown()
  .messages(errorMessages);
